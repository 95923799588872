@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_content {
    margin: 16px 0;

    @include breakpoint(xlarge) {
        margin: 48px 0;
    }

    .heading-h1 {
        margin-bottom: 32px;
    }
}

;@import "sass-embedded-legacy-load-done:94";